import { SHA256, enc } from 'crypto-js';

export const dateFormatter = (this_date) => {
    const tmp_date = new Date(this_date);

    // Get the year, month, and day
    const year = tmp_date.getFullYear();
    const month = String(tmp_date.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so add 1
    const day = String(tmp_date.getDate()).padStart(2, '0'); // Pad single digits with a leading zero

    // Format as YYYY-MM-DD
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
}

export const appendQueryString = (arr) => {
    if (!Array.isArray(arr) || arr.length === 0) return "";

    return `?${arr.join('&')}`;
}

export const generateLoginCode = () => {
    const codeVerifier = generateRandomString(128); // Change the length as needed
    const codeChallenge = base64UrlEncode(SHA256(codeVerifier));
    return { codeVerifier, codeChallenge };
}

// function to generate a random string
function generateRandomString(length) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
}

// function to base64 URL encode a string
function base64UrlEncode(str) {
    return str.toString(enc.Base64)
        .replace(/\+/g, '-')
        .replace(/\//g, '_')
        .replace(/=/g, '')
        .substr(0, 43); // Ensure the code challenge is exactly 43 characters long
}